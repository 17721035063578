import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AWS from 'aws-sdk';
import './ConfirmPasswordReset.css'; // Import component-specific CSS file
import './aws-config'; // Import AWS configuration

const ConfirmPasswordReset = () => {
  const [username, setUsername] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const cognito = new AWS.CognitoIdentityServiceProvider();
    const params = {
      ClientId: process.env.REACT_APP_CLIENT_ID,
      Username: username,
      ConfirmationCode: verificationCode,
      Password: newPassword,
    };

    try {
      await cognito.confirmForgotPassword(params).promise();
      setMessage('Password reset successfully.');
      navigate('/admin');
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div className="reset-password-container">
      <h2>Confirm Password Reset</h2>
      <form onSubmit={handleSubmit} className="reset-password-form">
        <div className="form-group">
          <label htmlFor="username">Username or Email:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="verificationCode">Verification Code:</label>
          <input
            type="text"
            id="verificationCode"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="newPassword">New Password:</label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="reset-password-button">Reset Password</button>
      </form>
      <p>{message}</p>
    </div>
  );
};

export default ConfirmPasswordReset;
