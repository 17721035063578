import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './AuthContext'; 
import AnimatedBackground from './AnimatedBackground';
import HamburgerMenu from './hamburgerMenu';
import Home from './home';
import Admin from './admin';
import CreateAccount from './createAccount';
import Profile from './profile';
import AdminHome from './adminHome';
import Signup from './Signup';
import ProtectedRoute from './ProtectedRoute'; 
import Verification from './verification';
import InitiatePasswordReset from './InitiatePasswordReset';
import ConfirmPasswordReset from './ConfirmPasswordReset';
import './App.css';

function App() {
  return (
    <AuthProvider>
      <Router>
        <AnimatedBackground />
        <Routes>
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/Signup" element={<Signup />} />
          <Route path="/admin/verify" element={<Verification />} />
          {/* Directly use Route for Home without ProtectedRoute to allow unrestricted access */}
          <Route path="/" element={<Home />} />
          {/* Use ProtectedRoute for routes that require authentication */}
          <Route path="/admin/home" element={<ProtectedRoute><AdminHome /></ProtectedRoute>} />
          <Route path="/admin/createAccount" element={<ProtectedRoute><CreateAccount /></ProtectedRoute>} />
          <Route path="/admin/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          {/* Routes for Password Reset */}
          <Route path="/reset-password" element={<InitiatePasswordReset />} />
          <Route path="/confirm-password" element={<ConfirmPasswordReset />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <HamburgerMenu />
      </Router>
    </AuthProvider>
  );
}

export default App;
