import React, { useState, useRef, useEffect } from 'react';
import './styles.css';
import cornerImage from './images/Ninjas in Costumes.png';
import ninjaImage from './images/ninja.png';
import './loader.css'
import { transition } from './loader.js'
import {fetchNinjaByWristband} from './services/gateway.js'

const Home = () => {
  const [transitionDone, setTransitionDone] = useState(false);
  const [welcomeDone, setWelcomeDone] = useState(false);
  const inputRef = useRef(null);
  const [textBoxValue, setTextBoxValue] = useState('');
  const [display, setDisplay] = useState('');
  const welcome = "Welcome ninjas!\nPlease scan your wristband\nto see your stars!"

  useEffect(() => {
    const handleClick = (event) => {
      // Check if the clicked element is not the input or inside the input
      if (!inputRef.current.contains(event.target)) {
        // If so, refocus on the input
        inputRef.current.focus();
      }
    };
  
    // Automatically focus on the text box when the component mounts
    inputRef.current.focus();
  
    // Add click event listener to the document
    document.addEventListener('click', handleClick);
  
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
  

  const handleKeyPress = (event) => {
    // Check if Enter key is pressed
    if (event.key === 'Enter') {
      setTextBoxValue(inputRef.current.value)
      handleTransition()
      setDisplay("Your wristband \nhas not been set up yet. \nPlease ask a sensei \nto activate it!")
    }
  };

  useEffect(() => {
    if (transitionDone && welcomeDone){
      fetchNinjaByWristband(textBoxValue)
      .then ((data) => {
        if (data != null) {
          setDisplay(`Congratulations ${data[0].FName}!\nYou have earned\n ${data[0].NumStars} stars!`)
        }
      })
    }
  })

  const handleTransition = () => {
    // Reset states before starting the transition
    setTransitionDone(false);
    setWelcomeDone(false);

    // Clear our invisible textbox
    inputRef.current.value = "";

    // Start the transition after a short delay
    setTimeout(() => {
      setTransitionDone(true);
      transition();
    }, 100);

    // Set welcomeDone to true after the first animation completes
    setTimeout(() => {
      setWelcomeDone(true);
    }, 2200);

    // Reset states and text after the second animation completes
    setTimeout(() => {
      setTransitionDone(false);
      transition();
    }, 8000); 
  };
  

  return (
    <div className="home-container">
      {/* Overlay elements for animation */}
      <div className="overlay top"></div>
      <div className="overlay bottom"></div>

      {/* Loader animation */}
      <div className="loader">
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
        <div className="bar4"></div>
        <div className="bar5"></div>
        <div className="bar6"></div>
      </div>

      {/* Invisible text box */}
      <input
        ref={inputRef}
        type="text"
        className="invisible-text-box"
        onKeyDown={handleKeyPress}
      />

      <h1 className={`custom-font center-text`} key={transitionDone ? 'congratulations' : 'welcome'}>
        {!transitionDone ? (
          <div className="waviy">
            {Array.from(welcome).map((char, index) => {
              if (char === " ") {
                return (
                  <span style={{ '--i': index+1 }}>&nbsp;</span>
                )
              }
              if (char === "\n") {
                return (
                  <br />
                )
              }
              return (
                <span style={{ '--i': index+1 }}>{char}</span>
              )
            })}
          </div>
        ) : (
          welcomeDone ? <div className='waviy'> 
            {Array.from(display).map((char, index) => {
              if (char === " ") {
                return (
                  <span style={{ '--i': index+1 }}>&nbsp;</span>
                )
              }
              if (char === "\n") {
                return (
                  <br />
                )
              }
              return (
                <span style={{ '--i': index+1 }}>{char}</span>
              )
            })} 
          </div>
          : null
          )}
      </h1>
      {/* <button className="btn" onClick={handleTransition}>Begin Transition</button> */}
      <img src={cornerImage} alt=';' className="bottom-right-image" />
      <img src={ninjaImage} alt=';' className="ninja-image" />
    </div>

  );
};

export default Home;