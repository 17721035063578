import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AWS from 'aws-sdk';
import './InitiatePasswordReset.css'; // Import component-specific CSS file
import './aws-config'; // Import AWS configuration

const InitiatePasswordReset = () => {
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/admin'); // Adjust the path as needed
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const cognito = new AWS.CognitoIdentityServiceProvider();
    const params = {
      ClientId: process.env.REACT_APP_CLIENT_ID,
      Username: username,
    };

    try {
      await cognito.forgotPassword(params).promise();
      setMessage('Verification code sent to your email.');
      navigate('/confirm-password');
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div className="reset-password-container">
      <button className="back-arrow" onClick={handleBackClick}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 19L8 12L15 5"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <h2>Reset Password</h2>
      <form onSubmit={handleSubmit} className="reset-password-form">
        <div className="form-group">
          <label htmlFor="username">Username or Email:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="reset-password-button">
          Send Verification Code
        </button>
      </form>
      <p>{message}</p>
    </div>
  );
};

export default InitiatePasswordReset;
