import React, { useState, useEffect } from 'react';
import './styles.css';
import './profile.css';
import starIcon from './images/ninjaStar.png';
import { fetchNinjaByID, postNinjaTransaction, updateNinjaWristband, fetchAllTransactions } from './services/gateway';
import ninjaImg from './images/ninja_tac.png';

const Profile = () => {
  const [isLoading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState({
    fname: '',
    lname: '',
    stars: 0,
    impactID: '',
    wristbandID: ''
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    if (profileData.impactID) {
      fetchAllTransactions(profileData.impactID).then(data => {
        setTransactions(data.sort((a, b) => new Date(b.Date) - new Date(a.Date)));
      });
    }
  }, [profileData.impactID]);

  const handleImpactSearch = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.length > 1) {
      const data = await fetchNinjaByID(query);
      if (data) {
        setSearchResults(data);
      } else {
        setSearchResults([]);
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleNinjaSelect = async (ninja) => {
    setProfileData({
      fname: ninja.FName,
      lname: ninja.LName,
      stars: ninja.NumStars,
      impactID: ninja.ImpactID,
      wristbandID: ninja.WristbandID
    });
    setSearchQuery(ninja.ImpactID);
    setSearchResults([]);

    const transactionData = await fetchAllTransactions(ninja.ImpactID);
    setTransactions(transactionData.sort((a, b) => new Date(b.Date) - new Date(a.Date)));
  };

  const handleSubmit = async (e, formType) => {
    e.preventDefault();
    if (isLoading) return;

    setLoading(true);
    try {
        if (formType === 'update') {
            if (!profileData.impactID) {
                alert("Please select a Ninja first!");
                setLoading(false);
                return;
            }

            const changeStars = parseInt(e.target.querySelector('#starAdjustment').value);
            const reason = e.target.querySelector('#reason').value;
            const ninjaName = `${profileData.fname} ${profileData.lname}`;

            // Log the inputs to make sure they are correct
            console.log("Submitting star adjustment:", {
                impactID: profileData.impactID,
                changeStars,
                reason,
                ninjaName,
            });

            const response = await postNinjaTransaction(profileData.impactID, changeStars, reason, ninjaName);

            // Log the response to see what's being returned
            console.log("Response from postNinjaTransaction:", response);

            if (response && response["$metadata"] && response["$metadata"].statusCode === 200) {
                // Directly update the star count without re-fetching the profile
                setProfileData(prevData => ({
                    ...prevData,
                    stars: prevData.stars + changeStars, // Update the stars count locally
                }));

                const updatedTransactions = await fetchAllTransactions(profileData.impactID);
                setTransactions(updatedTransactions.sort((a, b) => new Date(b.Date) - new Date(a.Date)));

                e.target.querySelector('#starAdjustment').value = "";
                e.target.querySelector('#reason').value = "";

                alert("Successfully updated Ninja's star count");
            } else {
                throw new Error("Ninja Transaction is not Successful!");
            }
        } else if (formType === 'updateTag') {
            if (!profileData.impactID) {
                alert("Please select a Ninja first!");
                setLoading(false);
                return;
            }

            const updatedWristbandID = e.target.querySelector('#updateTag').value;
            const response = await updateNinjaWristband(profileData.impactID, updatedWristbandID);

            console.log("Response from updateNinjaWristband:", response);

            if (response && response["$metadata"] && response["$metadata"].statusCode === 200) {
                setProfileData(prevData => ({
                    ...prevData,
                    wristbandID: updatedWristbandID,
                }));

                const updatedTransactions = await fetchAllTransactions(profileData.impactID);
                setTransactions(updatedTransactions.sort((a, b) => new Date(b.Date) - new Date(a.Date)));

                e.target.querySelector('#updateTag').value = "";

                alert(`Successfully updated NFC Tag ID for ${profileData.fname}`);
            } else {
                throw new Error("Updating Ninja Wristband Failed.");
            }
        }
    } catch (err) {
        console.error(err);
    } finally {
        setLoading(false); // Ensure loading state is cleared
    }
  };

  return (
    <div className="home-container">
      <h1 className="custom-font-admin">Ninja Profile</h1>
      <div className="admin-container">
        <div className="forms-container">
          <div className="upper-forms-container">
            <form className="profile-form full-width-form profile-header">
              <div className="form-group">
                <label htmlFor="searchLabel">Search by Impact ID:</label>
                <input
                  type="text"
                  id="impactSearch"
                  name="impactSearch"
                  placeholder="Search by Impact ID"
                  value={searchQuery}
                  onChange={handleImpactSearch}
                />
                {searchResults.length > 0 && (
                  <ul className="search-results-list">
                    {searchResults.map((ninja, index) => (
                      <li key={index} onClick={() => handleNinjaSelect(ninja)}>
                        {ninja.ImpactID}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </form>
            <div className="student-info-section">
              <h2>Student Information</h2>
              <div className="star-box">
                <img src={starIcon} alt="Star" className="star-icon" />
                <span className="star-count">{profileData.stars} Stars</span>
              </div>
              <p><strong>Impact ID: </strong>{profileData.impactID}</p>
              <p><strong>First Name: </strong>{profileData.fname}</p>
              <p><strong>Last Name: </strong>{profileData.lname}</p>
              <p><strong>NFC Tag ID: </strong>{profileData.wristbandID}</p>
            </div>
          </div>
          <div className="lower-forms-container">
            <form className="profile-form lower-form" onSubmit={(e) => handleSubmit(e, 'update')}>
              <div className="form-group">
                <label htmlFor="starAdjustment">Adjust Stars:</label>
                <input type="number" id="starAdjustment" name="starAdjustment" required placeholder="Positive or Negative Number"/>
              </div>
              <div className="form-group">
                <label htmlFor="reason">Stars Adjustment Details:</label>
                <textarea
                  id="reason"
                  name="reason"
                  required
                  rows="3"
                  className="textarea-reason"
                  placeholder="Provide details to the star adjustment, such as breakdown for the session. If it is a redemption, be sure to note the prize redeemed by the ninja."
                ></textarea>
              </div>
              <button type="submit" className="submit-button" disabled={isLoading}>
                {isLoading ? 'Updating...' : 'Adjust Stars'}
              </button>
            </form>
            <div className="nfc-management lower-form">
              <form className="nfc-form lower-form" onSubmit={(e) => handleSubmit(e, 'updateTag')}>
                <div className="form-group">
                  <label htmlFor="updateTag">Update Ninja NFC Tag:</label>
                  <input type="text" id="updateTag" name="updateTag" placeholder="Focus on this field, then scan the new NFC tag to update." required />
                  <button type="submit" className="submit-button" disabled={isLoading}>
                    {isLoading ? 'Updating...' : 'Update Tag'}
                  </button>
                </div>
              </form>
              <div className="ninja-image-container">
                <img src={ninjaImg} alt="Ninja" className="ninjaImg" />
              </div>
            </div>
          </div>
          <div className="transaction-history-section">
            <h2>Transaction History</h2>
            <div className="transaction-list-container">
              <ul className="transaction-list">
                {transactions.length > 0 ? (
                  transactions.map((transaction, index) => (
                    <li key={index}>
                      <p><strong>Date:</strong> {transaction.Date}</p>
                      <p><strong>Stars Changed:</strong> {transaction.StarDifference}</p>
                      <p><strong>Description:</strong> {transaction.Description}</p>
                    </li>
                  ))
                ) : (
                  <p>No transactions available.</p>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
