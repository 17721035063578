export const fetchNinjaByWristband = async (wristbandID) => {
  try {
    const encodedWristbandID = encodeURIComponent(wristbandID);
    const apiUrl = `${process.env.REACT_APP_NINJA_API_URL}?WristbandID=${encodedWristbandID}`;
    console.log("fetchNinjaByWristband URL:", apiUrl);
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_NINJA_API_ID
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data in fetchNinjaByWristband:", error);
    return null;
  }
};

export const fetchNinjaByID = async (impactID) => {
  try {
    const apiUrl = `${process.env.REACT_APP_NINJA_API_URL}?ImpactID=${impactID}`;
    console.log("fetchNinjaByID URL:", apiUrl);
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_NINJA_API_ID
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data in fetchNinjaByID:", error);
    return null;
  }
};

export const postNinjaTransaction = async (impactID, changeStars, reason, ninjaName) => {
  console.log("postNinjaTransaction impactID:", impactID);
  try {
    const apiUrl = `${process.env.REACT_APP_TRANSACTION_API_URL}`;
    console.log("postNinjaTransaction URL:", apiUrl);
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "x-api-key": process.env.REACT_APP_NINJA_API_ID,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ImpactID: impactID,
        Description: reason,
        Ninja: ninjaName,
        StarDifference: changeStars
      })
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error posting data in postNinjaTransaction:", error);
    return null;
  }
};

export const updateNinjaWristband = async (impactID, updatedWristbandID) => {
  try {
    const apiUrl = `${process.env.REACT_APP_NINJA_API_URL}`;
    console.log("updateNinjaWristband URL:", apiUrl);
    const response = await fetch(apiUrl, {
      method: "PUT",
      headers: {
        "x-api-key": process.env.REACT_APP_NINJA_API_ID,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ImpactID: impactID,
        wristbandID: updatedWristbandID
      })
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating data in updateNinjaWristband:", error);
    return null;
  }
};

export const createNinja = async (fname, lname, impactID, wristband, starAmount) => {
  try {
    const apiUrl = `${process.env.REACT_APP_NINJA_API_URL}`;
    console.log("createNinja URL:", apiUrl);
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "x-api-key": process.env.REACT_APP_NINJA_API_ID,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ImpactID: impactID,
        FirstName: fname,
        LastName: lname,
        NumStars: starAmount,
        WristbandID: wristband
      })
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error creating ninja in createNinja:", error);
    return null;
  }
};

export const fetchAllTransactions = async (impactID) => {
  try {
      const apiUrl = `${process.env.REACT_APP_TRANSACTION_API_URL}?ItemType=Transaction&ImpactID=${impactID}`;
      const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
              "x-api-key": process.env.REACT_APP_NINJA_API_ID
          }
      });
      const data = await response.json();
      console.log("Fetched Transactions:", data);  // Log the entire response
      return data;
  } catch (error) {
      console.error("Error fetching transactions:", error);
      return [];
  }
};
